import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { useInView } from 'react-intersection-observer';

import CupSvgComponent from '@illustration-components/cup.svg';
import { isBrowser } from '@utils';
import { mqdown } from '@mq';

const STEP = 0.2;

const CupIllustration = () => {
  const cupAnimation = useRef(null);

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '-30% 0px -30% 0px',
  });

  useEffect(() => {
    cupAnimation.current = gsap.timeline({
      repeat: -1,
      repeatDelay: 0.8,
      yoyo: true,
    });

    cupAnimation.current
      .to('.cup-frame-1', {
        opacity: 0,
        ease: 'steps(1)',
        duration: 0,
      })
      .fromTo(
        '.cup-frame-2',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: 'steps(1)',
          duration: STEP,
        },
        `<-${STEP / 2}`
      )
      .to('.cup-frame-2', {
        opacity: 0,
        ease: 'steps(1)',
        duration: 0,
      })
      .fromTo(
        '.cup-frame-3',
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: 'steps(1)',
          duration: STEP,
        },
        `<-${STEP / 2}`
      );

    cupAnimation.current.pause();

    const hourHandOneAnimation = gsap.fromTo(
      '.hour-hand-1',
      {
        rotate: 120,
        transformOrigin: '50% 104%',
      },
      {
        rotate: 480,
        duration: 5,
        repeat: -1,
        transformOrigin: '50% 104%',
        ease: 'linear',
      }
    );

    const hourHandTwoAnimation = gsap.to('.hour-hand-2', {
      rotate: 360,
      duration: 5,
      repeat: -1,
      transformOrigin: '50% 104%',
      ease: 'linear',
    });

    const minuteHandOneAnimation = gsap.fromTo(
      '.minute-hand-1',
      {
        rotate: -90,
        transformOrigin: '102% 0%',
      },
      {
        rotate: 270,
        duration: 2,
        repeat: -1,
        transformOrigin: '102% 0%',
        ease: 'linear',
      }
    );

    const minuteHandTwoAnimation = gsap.to('.minute-hand-2', {
      rotate: 360,
      duration: 2,
      repeat: -1,
      transformOrigin: '102% 0%',
      ease: 'linear',
    });

    // eslint-disable-next-line consistent-return
    return () => {
      if (cupAnimation.current) {
        cupAnimation.current.kill();
      }

      if (hourHandOneAnimation) {
        hourHandOneAnimation.kill();
      }

      if (hourHandTwoAnimation) {
        hourHandTwoAnimation.kill();
      }

      if (minuteHandOneAnimation) {
        minuteHandOneAnimation.kill();
      }

      if (minuteHandTwoAnimation) {
        minuteHandTwoAnimation.kill();
      }
    };
  }, []);

  useEffect(() => {
    if (inView) {
      cupAnimation.current.play();
    } else {
      cupAnimation.current.pause();
    }
  }, [inView]);

  if (!isBrowser) {
    return null;
  }

  return (
    <Container ref={ref}>
      <CupSvgComponent />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  ${mqdown.small} {
    margin-top: calc(var(--space-xxs) * -1);
  }
`;

export default CupIllustration;
